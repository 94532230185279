<template lang="pug">
#edit-carrier(v-esc-close="cancel" :class="panelClass")
  icon.close-panel(data="@icon/x.svg" @click="cancel" width="16" height="16" title="Close")

  h1 Edit Carrier

  form#edit-carrier-form
    .form-field(:class="{'field-error': nameError }")
      label Name (ie: 'Fabric')
        input(type="text" v-model.trim="form.name" placeholder="Name")
        .form-error(v-if="nameError") Name is required

    .form-field(:class="{'field-error': identifierError.invalid }")
      label Identifier (ie: 'FAB')
        input.to-uppercase(type="text" v-model.trim="form.identifier" v-mask="'AAA'" placeholder="Identifier" :disabled="carrierHasOrdered")
        .form-error(v-if="identifierError.invalid") {{ identifierError.message }}
        .form-error(v-if="carrierHasOrdered") Can't modify identifier once carrier has placed an order


  .panel-footer
    button.cancel-btn(@click="cancel") Cancel
    button.action-btn(@click="update") Update
  </template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
  props: {
    carrier: {
      type: Object,
      required: true,
    },
  },

  validations: {
    form: {
      name: {
        required,
      },
      identifier: {
        required,
        // TODO: Should also be unique
      },
    },
  },

  data() {
    return {
      panelClass: '',
      form: { ...this.carrier },
    }
  },

  computed: {
    carrierHasOrdered,
    nameError,
    identifierError,
  },

  watch: {},

  mounted() {
    setTimeout(() => {
      this.panelClass = 'is-visible'
    }, 100)
  },

  methods: {
    update,
    cancel,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
function carrierHasOrdered() {
  return this.carrier.ordersCount > 0
}

function nameError() {
  return !this.$v.form.name.required && this.$v.form.name.$error
}

function identifierError() {
  const result = { message: '', invalid: false }

  if (!this.$v.form.identifier.$dirty) {
    return result
  }

  if (!this.$v.form.identifier.required && this.$v.form.identifier.$error) {
    result.invalid = !this.$v.form.identifier.required && this.$v.form.identifier.$error
    result.message = 'Identifier is required'
  }

  // TODO: Test for uniqueness

  return result
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function cancel() {
  this.panelClass = ''
  setTimeout(() => {
    this.$panel.close('EditCarrier')
  }, 250)
}

function update() {
  this.$v.$touch()

  if (!this.$v.$error) {
    console.log('EditCarrier.vue :56', 'TODO: update carrier', this.form)
    this.cancel()
  }
}
</script>
